@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.btn {
  text-decoration: none;
  color: $color-white;
  background-color: $color-default;
  padding: 1.45rem 3.2rem;
  line-height: 1.92rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 1.3rem;
  border: 1px solid transparent;
  border-radius: 24px;
  cursor: pointer;
  white-space: nowrap;
  min-width: auto;

  &:hover {
    background-color: $color-light-grey;
  }

  @include respond(big-desktop) {
    border-radius: 36px;
  }
}

.disabled {
  background-color: $color-soft !important;
  border: 1px solid $color-soft !important;
  pointer-events: none;
}

.disabledIcon {
  background-color: $color-background !important;
  border: 1px solid $color-background !important;
  pointer-events: none;
}

.iconQuantity {
  width: 36px;
  height: 36px;
  stroke-width: 1px;
  color: $color-default;

  @include respond(tab-port) {
    width: 40px;
    height: 40px;
  }
}

.icon {
  width: 2.4rem;
  height: 2.4rem;

  @include respond(mobile) {
    width: 3rem;
    height: 3rem;
  }
}

.iconLink {
  text-decoration: none;
}

.iconButton {
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1.2rem;
  font-weight: 400;
  font-size: $text-base;
  line-height: 1rem;
  color: $color-default;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  min-width: auto;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }

  & img {
    @include respond(big-desktop) {
      width: 2.6rem;
      height: 2.6rem;
    }

    @include respond(tab-port) {
      width: 3.3rem;
      height: 3.3rem;
    }
  }
}

.backIcon {
  width: 2.2rem !important;
  height: 2.2rem !important;
}

.capitalize {
  text-transform: initial;
}

.disabledBtn {
  color: $color-light-grey;
  cursor: not-allowed;
}
