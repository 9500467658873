@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  gap: 6.4rem;
  width: 100%;

  @include respond(tablet) {
    flex-direction: row-reverse;
  }
}

.navigationList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 650px;

  @include respond(tablet) {
    flex-direction: column;
    max-width: 100%;
    gap: 3.2rem;
  }
}

.navItem {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.link {
  position: relative;
  font-weight: 450;
  line-height: 1.8rem;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-default;
  font-size: $text-base;
}
