@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.heroContainer {
  padding-inline: 3.2rem;

  @include respond(mobile) {
    padding-inline: 0;
  }
}

.heroContainer,
.appreciatedDesignContainer {
  @include respond(big-desktop) {
    margin: 0 auto;
  }
}

.specificationWrapper,
.supportWrapper {
  margin-bottom: 2.9rem;
  padding-top: 8.6rem;
  padding-bottom: 13rem;
  padding-inline: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-background;

  @include respond(tablet) {
    padding-inline: 9rem;
    margin-inline: 0;
  }

  @include respond(mobile) {
    padding-inline: 1rem;
    margin-inline: 0;
    padding-top: 4rem;
    padding-bottom: 7rem;
  }
}

.supportWrapper {
  margin-inline: 3.2rem;
  padding-inline: 18%;

  @include respond(mobile) {
    padding-inline: 3.2rem;
    margin-inline: 0 !important;
  }
}

.contentWrapper,
.contentWrapperSoftware {
  width: 100%;
  height: 100%;
}

.contentWrapperSoftware {
  display: flex;
  flex-direction: column;
  gap: 7.2rem;
}

.header,
.headerSoftware {
  margin-bottom: 8.2rem;

  & h2 {
    font-size: $text-3xl;
    font-weight: 600;
    line-height: 3.8rem;

    @include respond(tab-port) {
      padding-inline: 1.3rem;
    }
  }
}

.headerSoftware {
  margin-bottom: 0;
}

.innerWrapper,
.innerWrapperSoftware {
  padding-inline: 1.3rem;
  display: flex;
  flex-direction: column;
  gap: 7.2rem;
}

.innerWrapperSoftware {
  @include respond(mobile) {
    padding-inline: 0;
  }
}
