@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.containerWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16rem;

  @include respond(tablet) {
    padding-bottom: 6.4rem;
    gap: 6rem;
  }

  @include respond(tablet) {
    flex-direction: column;
    gap: 5.6rem;
  }
}

.container {
  color: $color-default;

  @include respond(big-desktop) {
    margin: 0 auto;
    max-width: 2000px;
  }

  @include respond(tablet) {
    padding-block: 6rem;
  }

  @include respond(tab-port) {
    padding-block: 4rem;
  }
}

.textContent {
  padding-left: 8.4rem;
  flex: 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  max-width: 50rem;

  @include respond(big-desktop) {
    max-width: 44rem;
  }

  @include respond(tab-land) {
    padding-left: 6.4rem;
  }

  @include respond(tablet) {
    padding-left: 0;
    gap: 1rem;
  }

  @include respond(tablet) {
    max-width: 100%;
  }
}

.title {
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 4.2rem;
  margin-bottom: 3.8rem;

  @include respond(tablet) {
    font-size: $text-2xl;
    line-height: 4rem;
    margin-bottom: 3.2rem;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 89%;

  @include respond(tablet) {
    width: 100%;
  }
}

.text {
  font-weight: 400;
  margin-top: 0.8rem;
  line-height: 2.4rem;
  white-space: pre-wrap;

  & a {
    color: $color-black;
  }

  & h3 {
    font-size: $text-2xl;
    line-height: 2.4rem;
    letter-spacing: 0.1em;
    font-weight: 500;

    @include respond(tablet) {
      font-size: $text-xl;
    }
  }

  @include respond(tablet) {
    margin-top: 1rem;
    font-size: $text-xl;

    line-height: 2.8rem;
  }

  @media only screen and (max-width: 360px) {
    font-size: 1.9rem;
  }
}

.imageWrapper {
  flex: 0 50%;

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
