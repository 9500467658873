@import '../../../../styles/abstracts/mixins';
@import '../../../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  margin-bottom: 1.6rem;
  color: $color-default;
  padding: 2.5rem 1.6rem;

  @include respond(tab-port) {
    margin-bottom: 0;
    padding: 2rem 2.2rem;
  }
}

.productContainer {
  display: flex;
  align-items: center;

  @include respond(mobile) {
    align-items: flex-start;
  }
}

.thumbnailContainer {
  img {
    object-fit: contain;
  }
}

.metaContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  margin-left: 2.4rem;
  width: 100%;
}

.smallContainerLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.4rem;
  flex: 50%;
}

.smallContainerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2.4rem;
  flex: 50%;
}

.info {
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-weight: 500;
  font-size: $text-sm;
  line-height: 2rem;
}

.titleLink {
  cursor: pointer;
  font-weight: 500;
  font-size: $text-xl;

  line-height: 2.4rem;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-default;

  &:hover {
    text-decoration: underline;
  }

  @include respond(tab-port) {
    font-size: $text-lg;
    line-height: 2.4rem;
  }
}

.color {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.7rem;
  color: $color-dark-grey;
}

.infoMetaContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.infoMeta {
  font-weight: 400;
  font-size: $text-sm;
  line-height: 1.4rem;
  color: $color-light-grey;
}

.infoSpecialText {
  font-weight: 500;
}

.quantityContainer {
  display: flex;
  flex-direction: row;
}

.quantitySelect {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  & button {
    & svg {
      width: 3.2rem;
      height: 3.2rem;

      @include respond(tab-port) {
        width: 3rem;
        height: 3rem;
      }
    }

    @media only screen and (max-width: 350px) {
      width: 3.8rem;
      height: 3.8rem;
    }

    @media only screen and (max-width: 280px) {
      width: 4rem;
      height: 4rem;
    }
  }

  @media only screen and (max-width: 375px) {
    gap: 1rem;
  }
}

.priceContainter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}

.price {
  font-weight: 500;
  line-height: 1.9rem;

  @include respond(tab-port) {
    font-size: $text-lg;
    line-height: 2.4rem;
  }
}

.deleteButton {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

// MOBILE QUERY

.mobileContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: stretch;
  margin-left: 1.25rem;
}

.mobileContainerItem {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  line-height: 2.25rem;
  align-items: flex-start;
}

.mobileProductInfo {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @include respond(tab-port) {
    gap: 1.2rem;
  }
}

.mobileContainerPrice {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: $text-xl;
  line-height: 2.73rem;
  padding-top: 1rem;

  @media only screen and (max-width: 320px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

.deleteButton {
  background-color: inherit;
  border: none;
  cursor: pointer;
}
