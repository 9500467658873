@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.required {
  color: $color-error;
}

.rowWrapper {
  display: flex;
  gap: 2.4rem;

  @include respond(mobile) {
    gap: 1.25rem;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;

  @include respond(mobile) {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.label {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.9rem;

  @include respond(mobile) {
    font-size: $text-lg;
    line-height: 2.38rem;
  }
}
