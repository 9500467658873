@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.footer {
  background-color: $color-default;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;
  padding: 8rem 0;
}

.navigation {
  display: flex;
  gap: 24rem;

  @include respond(tab-port) {
    gap: 4rem;
    flex-direction: column;
  }
}

.copy {
  font-weight: 450;
  color: $color-background;

  @include respond(big-desktop) {
    font-size: $text-xl;
  }
}
