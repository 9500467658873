@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.container {
  margin-top: 8rem;
  height: calc(100vh - 17rem);
  min-width: 35rem;
  margin-inline: 3rem;
}

.returnBtn {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;

  @include respond(mobile) {
    padding-bottom: 1.25rem;
    margin-bottom: 3.125rem;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  text-decoration: none;
  color: $color-default;

  & p {
    line-height: 1.3rem;
    font-weight: 500;

    @include respond(mobile) {
      font-size: $text-xl;

      line-height: 1.6rem;
    }
  }
}

.formWrapper {
  display: flex;
  justify-content: center;
}
