@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  color: $color-default;
  flex-direction: column;
  gap: 3.2rem;
  margin-top: 4rem;
  margin-bottom: 12rem;

  @include respond(tab-port) {
    margin-top: 0;
  }
}

.mainContent {
  display: flex;
  gap: 12rem;

  @media only screen and (max-width: 1280px) {
    gap: 8rem;
    flex-direction: column;
  }

  @include respond(tablet) {
    flex-direction: column;
    gap: 8rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  @include respond(mobile) {
    gap: 3.5rem;
  }
}

.leftColWrapper {
  display: flex;
  flex-direction: row-reverse;
  flex: 0 50%;
  gap: 2.4rem;

  @include respond(tablet) {
    flex: 100%;
    max-width: max-content;
    flex-direction: column;
  }
}

.rightColWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  font-size: $text-sm;
  width: 100%;
  flex: 0 50%;

  @include respond(tablet) {
    flex: 100%;
  }
}

.titleWrapper {
  margin-bottom: 0.8rem;

  & h1 {
    width: 100%;
    font-weight: 450;
    font-size: $text-xl;

    line-height: 2.3rem;
    text-transform: uppercase;
    color: $color-default;
    align-items: center;
    letter-spacing: 0.1em;

    @include respond(tablet) {
      font-size: $text-3xl;
      line-height: 3.6rem;
    }
  }

  @include respond(tablet) {
    margin-bottom: 1rem;
  }
}

.subtitleWrapper {
  margin-bottom: 2.4rem;
  width: 80%;

  & h2 {
    font-weight: 600;
    font-size: $text-3xl;
    line-height: 3.8rem;
    color: $color-default;

    @include respond(tablet) {
      font-size: $text-3xl;
      line-height: 3.6rem;
    }
  }

  @include respond(tablet) {
    margin-bottom: 3rem;
    width: 100%;
  }
}

.descriptionWrapper {
  margin-bottom: 2.4rem;
  width: 70%;

  & p {
    font-weight: 400;

    line-height: 2.1rem;

    @include respond(tablet) {
      font-weight: 400;
      font-size: text-xl;
      line-height: 2.625rem;
    }
  }

  @include respond(tablet) {
    width: 100%;
  }
}

.productAttr {
  display: flex;
  gap: 0.6rem;
}

.priceWrapper {
  margin-bottom: 1.9rem;
}

.price {
  font-weight: 400;
  font-size: $text-xl;

  line-height: 2.4rem;
  letter-spacing: 0.25rem;
  color: $color-default;

  @include respond(tab-port) {
    font-size: $text-2xl;
    line-height: 3rem;
  }
}

.addToCartSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @include respond(tab-port) {
    gap: 2rem;
  }

  @media only screen and (max-width: 280px) {
    gap: 1rem;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.formInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flexRowWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.formWrapper {
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.error {
  font-weight: 400;
  font-size: $text-sm;
  line-height: 2.2rem;
  color: $color-error;
}

.formButton {
  padding: 1.2rem 2.4rem;
  width: fit-content;
  font-weight: 500;
  line-height: 2.4rem;

  @include respond(tablet) {
    font-size: $text-lg !important;
    line-height: 2.6rem !important;
  }
}

.addToCartBtn {
  display: flex;
  align-items: center;
  gap: 2rem;

  & p {
    text-wrap: wrap;
    font-weight: 600;
  }

  @include respond(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }

  & button {
    font-weight: 500;
    line-height: 2.4rem;
    padding: 1.2rem 2.4rem;

    @include respond(tab-port) {
      font-size: $text-xl;

      line-height: 3rem;
      padding: 1.5rem 3rem;
    }
  }
}

.askForPriceWrapper {
  @include links-wrapper();
  gap: 0.3rem;

  & .askForOffer {
    font-size: $text-xl;
    font-weight: 500;
  }

  & .link {
    @include link-style();
  }
}
