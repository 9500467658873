@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  margin-top: 5rem;
  height: calc(100vh - 30rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  & h2 {
    font-weight: 400;
    font-size: $text-4xl;
    line-height: 4.5rem;
  }
}

.link {
  text-decoration: none;
  color: $color-default;
  font-weight: 400;
  font-size: $text-2xl;
}

.link:hover {
  text-decoration: underline;
}
