@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.shortWrapper {
  width: 60%;

  @include respond(tablet) {
    width: 70%;
  }

  @include respond(mobile) {
    width: 100%;
  }
}

.info {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.6rem;
  color: $color-soft;

  @include respond(mobile) {
    line-height: 2rem;
  }
}

.required {
  color: $color-error;
}

.rowWrapper {
  display: flex;
  gap: 2.4rem;

  @include respond(mobile) {
    gap: 1.25rem;
  }
}

.inputWrapper,
.inputWrapperShort {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;

  & p {
    font-weight: 400;
    font-size: $text-sm;
    line-height: 1.4rem;
    color: $color-soft;
  }

  @include respond(mobile) {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.label {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.9rem;

  @include respond(mobile) {
    font-size: $text-lg;
    line-height: 2.38rem;
  }
}

.inputWrapperShort {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;
  flex: 1.2;

  @include respond(mobile) {
    gap: 1.25rem;
    margin-bottom: 2rem;
  }
}

.inputWrapperLong {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;
  flex: 2;

  @include respond(mobile) {
    gap: 1.25rem;
    margin-bottom: 2rem;
  }
}

.styledSelect {
  padding: 1.1rem 1.2rem;
  width: 100%;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 2.1rem;
  color: $color-soft;
  border: 2px solid $color-background;

  @include respond(mobile) {
    padding: 0 1rem;
    font-size: $text-lg;
    line-height: 2.6rem;
  }
}

.styledInput {
  padding: 1.1rem 1.2rem;
  width: 100%;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 2.1rem;
  border: 2px solid $color-background;

  @include respond(mobile) {
    font-size: $text-lg;
    line-height: 2.6rem;
  }
}
