@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.menuWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  position: sticky;
  top: 2px;
  z-index: 150;
}

.logoWrapper {
  & img {
    @include respond(tablet) {
      height: 4.2rem;
    }
  }
}

.iconsWrapper {
  display: flex;
  align-items: center;
}

.menu {
  position: fixed;
  padding-top: 5vh;
  top: -100vh;
  z-index: 100;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;

  &.opened {
    transition: all 0.3s ease-in-out;
    transition-delay: 0.2s;
    position: fixed;
    top: 0px;
  }
}

.mobileCartBtn {
  position: relative;

  & img {
    width: 2.85rem;
    height: 2.85rem;

    @include respond(tab-port) {
      width: 3rem;
      height: 3rem;
    }

    @include respond(mobile) {
      width: 3rem;
      height: 3rem;
    }
  }
}

.itemCounterMobile {
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  background-color: $color-light-grey;
  font-size: $text-sm;
  line-height: 1.4rem;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(tab-port) {
    right: -0.25rem;
  }
}

.localeCartContainer {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
