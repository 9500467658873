@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  display: flex;
  gap: 4rem;
  margin: 6rem 0;
  padding: 0;

  @include respond(tablet) {
    flex-direction: column;
  }
}
