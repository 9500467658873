@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.container {
  margin-bottom: 5rem;
  min-width: 35rem;
}

.returnBtn {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;
}

.link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  text-decoration: none;
  color: $color-default;

  & p {
    line-height: 1.3rem;
    font-weight: 500;

    @include respond(mobile) {
      font-size: $text-xl;

      line-height: 1.6rem;
    }
  }
}

.btnPay {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  margin-top: 2.4rem;

  @include respond(mobile) {
    margin-top: 3rem;
  }
}

.inBtnPay {
  display: flex;
  padding: 1.45rem 3.2rem;
  font-size: $text-sm;
  line-height: 1.8rem;
  text-transform: uppercase;
  color: $color-white;
  background-color: $color-black;
  border-radius: 24px;

  @include respond(mobile) {
    padding: 1.8rem 4rem;
    font-size: $text-lg;
    line-height: 2.25rem;
  }
}
