@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.bannerContainer {
  padding-inline: 3.2rem;

  @include respond(big-desktop) {
    margin: 0 auto;
  }
}

.container {
  padding-bottom: 3.8rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: $color-default;

  @include respond(tablet) {
    padding-bottom: 3rem;
  }
}

.textContent {
  padding-left: 6.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @include respond(tab-land) {
    padding-left: 6.4rem;
  }

  @include respond(tablet) {
    padding-left: 0;
    gap: 1rem;
  }
}

.title {
  font-weight: 600;
  font-size: $text-5xl;
  line-height: 5.6rem;

  @include respond(tablet) {
    font-size: $text-4xl;
    line-height: 5rem;
  }
}

.line {
  border: 0;
  height: 1px;
  width: 95%;
  background: $color-black;
}
