@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: row;
  padding: 2.4rem 1.6rem;
  background-color: $color-white;
  color: $color-default;
  margin-bottom: 5.2rem;

  & select {
    height: 4.5rem;

    @include respond(tablet) {
      max-height: 4.5rem !important;
    }
  }

  & input,
  select {
    @include respond(tablet) {
      max-height: 4.5rem;
      line-height: 1.8rem;
    }
  }

  @include respond(tab-port) {
    padding: 3rem 3.75rem 1.5rem 3.75rem;
    margin: 0;
  }

  @include respond(mobile) {
    padding: 3rem 2.5rem;
  }
}

.wrapper {
  width: 72%;

  @include respond(tablet) {
    width: 85%;
  }

  @include respond(mobile) {
    width: 100%;
  }
}

.shortWrapper {
  width: 60%;

  @include respond(tablet) {
    width: 70%;
  }

  @include respond(mobile) {
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  @include respond(mobile) {
    gap: 0.5rem;
  }
}

.title {
  font-weight: 500;
  line-height: 2.2rem;
  margin-bottom: 2.4rem;

  @include respond(mobile) {
    font-size: $text-xl;

    line-height: 2.75rem;
    margin-bottom: 3rem;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  list-style: none;
  margin: 2.4rem 0;

  @include respond(mobile) {
    gap: 3rem;
    margin: 3.2rem 0;
  }
}

.checkboxLabel {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.9rem;
  padding-left: 1rem;
  cursor: pointer;

  @include respond(mobile) {
    line-height: 2.2rem;
    padding-left: 1.5rem;
  }
}

.checkboxDot {
  width: 2rem;
  height: 2rem;
  accent-color: $color-default;
  cursor: pointer;

  @include respond(mobile) {
    width: 2rem;
    height: 2rem;
  }
}

.checkboxOption {
  display: flex;
  align-items: center;
}

.required {
  color: $color-golden-tainoi;
}

.rowWrapper {
  display: flex;
  gap: 2.4rem;

  @include respond(mobile) {
    gap: 1.25rem;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;

  @include respond(mobile) {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.label {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.9rem;

  @include respond(mobile) {
    line-height: 2.38rem;
  }
}

.inputWrapperShort {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;
  flex: 1.2;

  @include respond(mobile) {
    gap: 1.25rem;
    margin-bottom: 2rem;
  }
}

.inputWrapperLong {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;
  flex: 2;

  @include respond(mobile) {
    gap: 1.25rem;
    margin-bottom: 2rem;
  }
}

.styledSelect {
  padding-inline: 1.2rem;
  width: 100%;
  height: 3.8rem;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 2.1rem;
  border: 1.5px solid $color-error;
}

.styledInput {
  padding: 1.1rem 1.2rem;
  width: 100%;
  height: 3.8rem;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 2.1rem;
  border: 1.5px solid $color-error;
  letter-spacing: 2px;
}
