@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.bannerContainer,
.container {
  padding-inline: 3.2rem;
}

.container {
  padding-bottom: 3.8rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: $color-default;

  @include respond(tablet) {
    padding-bottom: 3rem;
  }
}

.contentWrapper {
  padding-top: 10.5rem;
  padding-bottom: 15.5rem;
  padding-inline: 28.1rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  background-color: $color-background;

  @include respond(tab-land) {
    padding-block: 9.5rem;
    padding-inline: 15.1rem;
  }

  @include respond(tablet) {
    padding-block: 8.5rem;
    padding-inline: 5.1rem;
    gap: 8rem;
  }

  @include respond(mobile) {
    padding-inline: 4rem;
  }
}

.itemWrapper,
.textWrapper,
.textItem {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @include respond(tablet) {
    gap: 1.75rem;
  }
}

.itemWrapper {
  gap: 8rem;

  @include respond(tablet) {
    gap: 4rem;
  }
}

.itemTitle {
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 3.8rem;
}

.headline,
.text,
.attachment {
  font-weight: 600;
  font-size: $text-xl;
  line-height: 2.8rem;

  @include respond(tablet) {
    font-size: $text-xl;
    line-height: 3rem;
  }
}

.attachment {
  margin-top: 3.5rem;
  color: $color-black;
  font-weight: 600;
  font-size: $text-2xl;
  line-height: 2.8rem;

  @include respond(tablet) {
    font-size: $text-xl;
    line-height: 3rem;
  }
}

.text {
  font-size: $text-lg;
}
