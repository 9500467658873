@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.wrapper {
  background-color: $color-background;
  padding: 2rem;
  width: fit-content;
  margin: 0 auto;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heading {
  font-size: $text-2xl;
}
