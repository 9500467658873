@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.checkbox {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  list-style: none;
  margin: 2.4rem 0 1.2rem 0;

  @include respond(mobile) {
    gap: 3rem;
    margin: 3.2rem 0 1.5rem 0;
  }
}

.checkboxLabel {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.9rem;
  padding-left: 1rem;
  cursor: pointer;

  @include respond(mobile) {
    font-size: $text-base;
    line-height: 2.38rem;
    padding-left: 1.25rem;
  }
}

.checkboxDot {
  width: 2rem;
  height: 2rem;
  accent-color: $color-default;
  cursor: pointer;

  @include respond(mobile) {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.checkboxOption {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 0.8rem;
  min-height: 5rem;
}

.checkboxDiv {
  display: flex;
  align-items: center;
}

.info {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 1.6rem;
  color: $color-soft;

  @include respond(mobile) {
    line-height: 2rem;
  }
}
