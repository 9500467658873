@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.section {
  margin-bottom: 0;
  padding-block: 8rem;
  color: $color-default;

  @include respond(big-desktop) {
    margin: 0 auto;
    max-width: 2000px;
  }

  @include respond(tablet) {
    padding-block: 6rem;
  }

  @include respond(tab-port) {
    padding-block: 4rem;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  @include respond(mobile) {
    flex-direction: column;
    gap: 6rem;
  }
}

.content {
  padding-inline: 8.4rem;
  flex: 0 50%;

  @include respond(tab-land) {
    padding-inline: 6.4rem;
  }

  @include respond(tab-port) {
    padding-inline: 0;
  }

  @include respond(mobile) {
    flex: 100%;
  }
}

.text {
  margin-top: 0.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  white-space: pre-wrap;

  & a {
    color: $color-black;
  }
}

.title {
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 4.2rem;

  @include respond(tablet) {
    font-size: $text-2xl;
    line-height: 4rem;
  }
}
