@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  margin-block: 4.2rem;
  padding-block: 14.9rem;
  padding-inline: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.4rem;
  background-color: $color-background;

  @include respond(tablet) {
    margin-block: 6rem;
  }

  @include respond(tab-port) {
    margin-block: 2rem;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: $text-2xl;
  line-height: 3.2rem;
  max-width: 67.3rem;

  @include respond(tablet) {
    font-size: $text-xl;
    line-height: 4rem;
    max-width: 45rem;
  }
}

.signature {
  margin-top: 2.7rem;
  font-weight: 400;
  font-size: $text-lg;
  line-height: 2.8rem;
  max-width: 66rem;
  text-align: right;
  width: 100%;
  white-space: pre-wrap;

  @include respond(tablet) {
    font-size: $text-base;

    line-height: 2.8rem;
  }
}
