@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: $color-default;
}

.container {
  padding: 4rem;
  background-color: $color-background;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
