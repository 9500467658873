@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.container {
  margin-top: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: $color-default;

  @include respond(tablet) {
    padding-bottom: 3rem;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @include respond(tablet) {
    gap: 1rem;
  }
}

.line {
  border: 0;
  height: 1px;
  width: 100%;
  background: $color-black;
}

.logoSectionWrapper {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15rem;

  & img {
    @include respond(tab-port) {
      width: 70%;
      height: auto;
    }

    @include respond(mobile) {
      width: 80%;
    }
  }

  @include respond(tab-port) {
    margin-top: 4rem;
    gap: 12rem;
  }

  @include respond(mobile) {
    gap: 10rem;
  }
}
