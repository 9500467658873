@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';
@import '../../styles/base/_common.scss';

.input {
  width: 100%;
  height: 4.4rem;
  border: 1.5px solid $color-background;
  padding: 1rem 1.6rem;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 2.1rem;
}

.inputError {
  border: 1.5px solid $color-error;
}

.error {
  color: $color-error !important;
  font-size: $text-sm !important;
  line-height: 2rem !important;
}
