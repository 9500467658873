@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.wrapper {
  position: relative;
  & a {
    display: block;
    height: 18px;

    & img {
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.languageSwitcher {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-family: 'neue-haas-grotesk-display', sans-serif;
  font-size: $text-lg;
}
.dropdownList {
  list-style: none;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 50%;
  transform: translateX(50%);
  background-color: $color-white;
  padding: 5px 0;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
.dropdownItem {
  padding: 10px 20px;
}
