@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  margin-top: 4rem;
  margin-bottom: 4rem;

  @include respond(mobile) {
    margin-top: 2rem;
    gap: 2rem;
  }
}
