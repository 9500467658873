@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.nav {
  padding-block: 2.4rem;
  position: sticky;
  top: 0;
  background-color: $color-white;
  z-index: 1000;

  @include respond(tab-land) {
    gap: 8rem;
  }
}

.menu {
  display: flex;
  align-items: center;
  gap: 10rem;

  @include respond(tab-land) {
    gap: 8rem;
  }

  @include respond(tablet) {
    flex-direction: row-reverse;
  }
}
