@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.selectVariant {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;
  margin-bottom: 2.9rem;

  & p {
    line-height: 2.4rem;

    &.pChecked {
      font-weight: 600;
    }
  }
}

.heading {
  font-size: $text-xl;
  font-weight: 500;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.9rem;
  height: 2.9rem;
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: 100%;
}

.colorSwatch {
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  padding: 1rem;
}

.checked {
  border: 2px solid $color-default;
}

.outOfStock {
  cursor: default !important;
  opacity: 0.3;
}

.valueBtn {
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  color: $color-default !important;
  border: 2px solid transparent;

  &:not(.outOfStock) {
    &:hover {
      background-color: #c9c9c9;
    }
  }

  &.checked {
    border: 2px solid #2e2e2e;
  }
}
