@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.orderNotes {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  gap: 1.6rem;
  padding: 2.4rem 1.6rem;

  @include respond(tab-port) {
    padding: 3rem 2.5rem;
  }
}

.notesHeader {
  font-weight: 500;
  line-height: 2.2rem;

  @include respond(mobile) {
    font-size: $text-xl;

    line-height: 2.75rem;
  }
}

.orderNotesText {
  width: 100%;
  border: 1px solid $color-soft;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 1.68rem;
  min-height: 9rem;
  padding: 1.6rem;
}
