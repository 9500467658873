@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heading {
  font-weight: 600;
  font-size: $text-xl;
  line-height: 2.2rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
}

.section {
  background-color: $color-gainsboro;
  padding: 12rem;

  @include respond(mobile) {
    padding: 4rem;
  }
}
