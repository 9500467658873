@import '../../styles/abstracts/variables';

.spinner {
  padding-block: 6rem !important;
  justify-content: center;
}

.noPadding {
  padding: 0 !important;
}

:export {
  primaryColor: $color-dark-grey;
  primaryColorLight: $color-space-grey;
}
