@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  border: 1px solid $color-background;
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header {
  font-size: $text-2xl;
  font-weight: 600;
}
