@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.inputWrapper {
  border-block: 1px solid $color-default;
  border-inline: none;
  border-radius: 12px;
  min-height: 5.5rem;

  @include respond(tablet) {
    max-width: 40rem;
  }
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-default;
  transition: background-color 5000s ease-in-out 0s;
}

.label {
  padding-right: 0.5rem;
  font-weight: 450;
  font-size: $text-sm;
  line-height: 2rem;

  @include respond(tablet) {
    font-size: $text-lg;
    line-height: 2.4rem;
  }
}

.input {
  padding: 0 1.5rem 1rem 1.5rem;
  width: 100%;
  min-height: 5.5rem;
  border: none;
  background: none !important;
}

.input:focus {
  outline: none;
}
