@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  width: 100%;
  gap: 7rem;
}

.logoWrapper {
  & img {
    @include respond(big-desktop) {
      height: 4.6rem;
      width: 17rem;
    }

    @include respond(tab-land) {
      height: 3.6rem;
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  width: 100%;
}

.cartBtn {
  position: relative;

  & img {
    width: 2.85rem;
    height: 2.85rem;

    @include respond(tab-port) {
      width: 3rem;
      height: 3rem;
    }

    @include respond(mobile) {
      width: 3rem;
      height: 3rem;
    }
  }
}

.itemCounter {
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  background-color: $color-light-grey;
  font-size: $text-sm;
  line-height: 1.2rem;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.localeCartContainer {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
