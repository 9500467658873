@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  background-color: $color-white;
  color: $color-default;
  height: 100vh;
  padding-top: 6rem;
  margin-bottom: 0;

  @include respond(tablet) {
    padding-top: 3rem;
  }
}

.wrapper {
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond(tablet) {
    margin-top: 25rem;
  }
}

.header {
  text-align: center;
  font-size: $text-5xl;
  line-height: 6rem;
  letter-spacing: -2.5px;
  font-weight: 400;

  @include respond(tablet) {
    font-size: $text-3xl;
    line-height: 5rem;
    letter-spacing: 0;
  }
}

.error {
  font-size: $text-7xl;
  font-weight: 600;
  color: $color-default;
  margin-block: 4rem;

  @include respond(tablet) {
    line-height: 5rem;
    margin-block: 8rem;
  }
}
