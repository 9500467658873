@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';
@import '../../styles/base/_common.scss';

.required {
  color: $color-error;
}

.inputWrapper {
  color: $color-light-grey;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 1.6rem;

  @include respond(mobile) {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.styledInput {
  padding: 1.1rem 1.2rem;
  width: 100%;
  height: 3.8rem;
  font-size: $text-sm;
  font-weight: 400;
  line-height: 2.1rem;
  border: 1px solid $color-light-grey;
  letter-spacing: 2px;

  @include respond(mobile) {
    font-size: $text-lg;
    line-height: 2.6rem;
  }
}
