@import '../../styles/abstracts/mixins';

.imageWrapper {
  margin-bottom: 16rem;

  & img {
    object-fit: cover;
    width: 100%;

    @include respond(mobile) {
      aspect-ratio: 1/1;
      height: 100%;
      width: 100%;
    }
  }

  @include respond(tablet) {
    margin-bottom: 6rem;
  }

  @include respond(tab-port) {
    margin-bottom: 5rem;
  }
}
