@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 5rem;
  width: 100%;

  @include respond(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(mobile) {
    grid-template-columns: 1fr;
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.heading {
  font-size: $text-xl;
  font-weight: 700;
  margin-bottom: 1rem;
  text-decoration: underline;
  margin-bottom: 2rem;
}

.phoneList {
  margin: 2rem 0;
  text-decoration: underline;
}

.page {
  text-decoration: underline;
}

.link {
  color: $color-default;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.linkContainer {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
