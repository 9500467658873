@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: $color-white;

  &:hover {
    text-decoration: underline;
  }
}

.text {
  font-size: $text-lg;
  font-weight: 500;
}
