@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.containerWrapper {
  background-color: $color-gainsboro;
  margin-top: 4rem;
}

.container {
  display: flex;
  justify-content: space-between;
  color: $color-default;
  background-color: $color-gainsboro;
  padding: $content-padding;

  @include respond(tablet) {
    padding: $content-padding-mobile;
    flex-direction: column;
    gap: 6rem;
  }
}

.content {
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;

  @include respond(big-desktop) {
    max-width: 42rem;
  }

  @include respond(tablet) {
    max-width: 100%;
  }
}

.imageWrapper {
  display: flex;
  flex: 0 50%;
  align-items: center;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.title {
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 4.2rem;

  @include respond(tablet) {
    font-size: $text-3xl;
    line-height: 3.8rem;
  }
}

.text {
  font-weight: 400;
  line-height: 2.4rem;

  @include respond(tablet) {
    font-size: $text-xl;

    line-height: 2.8rem;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
}

.btn {
  margin-top: 2.4rem;
  padding: 1.2rem 2.4rem;
  width: fit-content;
  font-weight: 500;
  line-height: 2.4rem;

  @include respond(tablet) {
    font-size: $text-lg !important;
    line-height: 2.6rem !important;
  }
}

.imageWrapper {
  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.error {
  font-weight: 400;
  font-size: $text-sm;
  line-height: 2.2rem;
  color: $color-error;
}
