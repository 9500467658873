@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.wrapper {
  position: relative;
  width: 45px;
  height: 50px;
  overflow: hidden;
  z-index: 200;

  @include respond(tab-port) {
    width: 40px;
  }
}

.menuIcon {
  position: absolute;
  width: 70px;
  height: 70px;
  right: -24px;
  top: -10px;

  & input {
    display: none;
  }

  & label {
    display: block;
    cursor: pointer;
    position: relative;
    width: 70px;
    height: 70px;
    max-width: 100px;
    max-height: 100px;
  }

  & path {
    fill: none;
    stroke: $color-black;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  & .line1,
  .line3 {
    --total-length: 111.22813415527344;
    --offset: -50.22813415527344;
  }

  & .line2 {
    --total-length: 99;
  }

  &.cross svg {
    & path {
      transform: translateX(30px);
    }

    & .line1,
    .line3 {
      --length: 22.627416998;
      --offset: -16.9705627485;
    }
    & .line2 {
      --length: 0;
      --offset: -20;
    }
  }
}
